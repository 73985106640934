@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "SFProDisplay-Regular";
  src: url(./assets/fonts/SFProDisplay-Regular.woff) format("woff"),
    url(./assets/fonts/SFProDisplay-Regular.woff2) format("woff2");
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}



/* Hide scrollbar for Chrome, Safari and Opera */
*:not(body)::-webkit-scrollbar {
  display: none;
}

*:not(body) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgb(64, 64, 64);
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #151515;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px; /* control the border thickness */
  border-radius: 24px;
  background: linear-gradient(90deg, #fff 0%, theme("colors.primary") 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.ltr-carousel {
  direction: ltr;
}

.gradient-border:hover:before {
  background: #fff;
}

.menu-gradient {
  background-image: linear-gradient(
    148deg,
    #ffffff 20%,
    theme("colors.primary") 224%
  );
}

.webkistyle3d {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.toggle-checkbox:checked {
  @apply: right-0;
  right: 0;
  border-color: theme("colors.primaryBg");
}

.toggle-checkbox:checked + .toggle-label {
  background-color: theme("colors.primaryBg");
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.twolinetextellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linetextellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 14;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-simple-star-rating > span {
  display: flex;
}

.react-simple-star-rating > span.filled-icons {
  overflow: hidden;
}

.react-simple-star-rating > span.filled-icons > .star-svg {
  min-height: 16px;
  min-width: 16px;
}

.mobiDatePicker > input {
  border-radius: 30px;
  border: none;
  outline: none;
  color: white;
  background-color: #11111180;
  padding: 16px 28px;
  display: flex;
  align-items: center;
}

.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 10px !important;
  background: #1d1d1d !important;
}
.Toastify__toast--error::after {
  content: url("./assets//svg/close.svg");
  position: absolute;
  color: black;
  font-size: 15px;
  font-weight: 700;
  left: 268px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  content: "";
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 10px !important;
  background: #1d1d1d !important;
}
.Toastify__toast--success::before {
  content: "";
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  content: url("./assets/svg/close.svg");
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 268px;
  padding-top: 14px !important;
}
.Toastify__toast--info {
  border: 1px solid #3a9ea5 !important;
  border-radius: 10px !important;
  background: #1d1d1d !important;
}
.Toastify__toast--info::before {
  content: "";
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--info::after {
  content: url("./assets/svg/close.svg");
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 268px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: white;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  /* margin-left: 25px !important; */
}
.Toastify__toast > button > svg {
  display: none;
}

.ribbon-wrapper {
  position: relative;
  z-index: 1;
}
.ribbon-front {
  background-color: #ffca24;
  text-shadow: 0 1px 2px #ffca24;
  height: 50px;
  width: 180px;
  position: relative;
  left: -10px;
  z-index: 2;
  font-size: 16px;
  /* font: 20px/50px bold Verdana, Geneva, sans-serif; */
  color: black;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}

.ribbon-front,
.ribbon-back-left,
.ribbon-back-right {
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -khtml-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
}

.ribbon-edge-topleft,
.ribbon-edge-topright,
.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  position: absolute;
  z-index: 1;
  border-style: solid;
  height: 0px;
  width: 0px;
}

.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  top: 50px;
}

.ribbon-edge-topleft,
.ribbon-edge-bottomleft {
  left: -10px;
  border-color: transparent #9b1724 transparent transparent;
}

.ribbon-edge-topleft {
  /* top: -5px; */
  border-width: 0px;
}
.ribbon-edge-bottomleft {
  border-width: 0 10px 0px 0;
}

.ribbon-edge-topright,
.ribbon-edge-bottomright {
  left: 200px;
  border-color: transparent transparent transparent #9b1724;
}

.ribbon-edge-topright {
  top: 0px;
  border-width: 0px 0 0 10px;
}
.ribbon-edge-bottomright {
  border-width: 0px;
}

@-webkit-keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
@keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
.showDates {
  height: 0;
  opacity: 0;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.showDates.expanded {
  height: auto;
  opacity: 1;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.showDatesContent {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.showDates.expanded .showDatesContent {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.glow {
  background: rgb(255, 255, 255);
  width: 40px;
  height: 100%;
  z-index: 4;
  position: absolute;
  -webkit-animation: flow 1.5s linear infinite;
  -moz-animation: flow 1.5s linear infinite;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  border-left: 1px solid #fff;
}
